import { useToastState } from "@react-stately/toast";
import {
  NotificationContentType,
  ToastBarContentType,
  ToastNotificationRegion,
} from "ds/ui";
import { ReactNode, createContext, useCallback, useContext } from "react";
import { useNotificationState } from "./NotificationReducer";

const TOAST_TIMEOUT = 5000;

const NotificationContext = createContext<{
  notify: (options: NotificationContentType) => void;
  toast: (options: ToastBarContentType) => void;
}>({ toast: () => {}, notify: () => {} });

export function useNotification() {
  return useContext(NotificationContext).notify;
}

export function useToast() {
  return useContext(NotificationContext).toast;
}

const MAX_VISIBLE_NOTIFICATIONS = 3;
const MAX_VISIBLE_TOASTS = 5;

export function ToastNotificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const notificationState = useNotificationState({
    maxVisibleToasts: MAX_VISIBLE_NOTIFICATIONS,
  });
  const toastState = useToastState<ToastBarContentType>({
    maxVisibleToasts: MAX_VISIBLE_TOASTS,
  });

  const showToast = useCallback(
    (payload: ToastBarContentType) =>
      toastState.add(payload, { timeout: TOAST_TIMEOUT }),
    [toastState.add],
  );

  const showNotification = useCallback(
    (payload: NotificationContentType) => notificationState.add(payload),
    [notificationState.add],
  );

  const haveNotifications =
    notificationState.visibleToasts.length > 0 ||
    toastState.visibleToasts.length > 0;

  return (
    <NotificationContext.Provider
      value={{ notify: showNotification, toast: showToast }}
    >
      {children}
      {haveNotifications && (
        <ToastNotificationRegion
          notificationState={notificationState}
          toastState={toastState}
        />
      )}
    </NotificationContext.Provider>
  );
}
